import { defineStore } from 'pinia'
import { ref } from 'vue'
export interface IToastPayload {
    bgClass?: string,
    body: string
}

export const usePageStore = defineStore('page', () => {
    const isLIFF = ref(false)
    const openMenu = ref(false)
    const pageLoading = ref(false)
    const showToast = ref(false)
    const toastInfo = ref({
        bgClass: '',
        body: ''
    })

    const pageCondition = (name: boolean) => {
        isLIFF.value = name
    }

    const toggleOpenMenu = () => {
        openMenu.value = !openMenu.value
    }

    const setPageLoading = (state: boolean) => {
        pageLoading.value = state
    }

    const toggleToast = (data: IToastPayload) => {
        if(data.bgClass) {
            toastInfo.value.bgClass = data.bgClass
        }
        toastInfo.value.body = data.body
        showToast.value = true
    }

    return {
        openMenu,
        isLIFF,
        pageLoading,
        showToast,
        toastInfo,
        pageCondition,
        toggleOpenMenu,
        setPageLoading,
        toggleToast
    }
})
