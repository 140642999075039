<script setup lang="ts">

</script>

<template>
  <div class="bg-loading"></div>
  <div class="d-flex justify-content-center custom-loading">
    <div class="spinner-border spinner-custom" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<style scoped>
.custom-loading {
  position: fixed !important;
  top: 50%;
  right: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.spinner-custom {
  width: 10rem;
  height: 10rem;
}
.bg-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: #898989;
  opacity: 0.6;
}
</style>
