import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { usePageStore } from '@/store/page'
import HomeView from '../views/HomeView.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import LoginLayout from '@/layouts/LoginLayout.vue'
import LIFFLayout from '@/layouts/LiffLayout.vue'
import NotFound from '@/components/NotFound.vue'
import { get } from 'lodash'
import { useSessionStore } from '@/store/sessions'

// @ts-ignore
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        meta: {
            layout: MainLayout,
            auth: true
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/attendance/AttendanceMain.vue'),
    },
    {
        path: '/manual-attendance',
        name: 'manual-attendance',
        meta: {
            layout: MainLayout,
            auth: true,
            role: 'request'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/attendance/AttendanceManual.vue'),
    },
    {
        path: '/request-attendance',
        name: 'req-attendance',
        meta: {
            layout: MainLayout,
            auth: true,
            role: 'request'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/attendance/AttendanceRequest.vue'),
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            layout: LoginLayout,
            login: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    },
    {
        path: '/liff',
        name: 'liff',
        meta: {
            liff: true,
            layout: LIFFLayout
        },
        children: [
            {
                path: 'request-work-in',
                name: 'Work In Request',
                component: () => import(/* webpackChunkName: "request-work-in" */'../views/liff/RequestWorkIn.vue'),
            },
            {
                path: 'request-leave',
                name: 'Leave Request',
                component: () => import(/* webpackChunkName: "request-leave" */  '../views/liff/RequestLeave.vue'),
            },
            {
                path: 'request-work-out',
                name: 'Work Out Request',
                component: () => import(/* webpackChunkName: "request-work-out" */'../views/liff/RequestWorkOut.vue'),
            },
            {
                path: 'register',
                name: 'Register',
                component: () => import(/* webpackChunkName: "request-work-out" */'../views/liff/RegisterEmployee.vue'),
            },
        ],
    },
    {
        path: '/employee',
        name: 'employee',
        component: () => import(/* webpackChunkName: "about" */ '../views/EmployeeView.vue'),
        meta: {
            layout: MainLayout,
            auth: true,
            role: 'employee'
        },
    },
    {
        path: '/holiday',
        name: 'holiday',
        component: () => import(/* webpackChunkName: "about" */ '../views/HolidayView.vue'),
        meta: {
            layout: MainLayout,
            auth: true,
            role: 'holiday'
        },
    },
    {
        path: '/attendance-report',
        name: 'attendance-report',
        component: () => import('../views/report/AttendanceReport.vue'),
        meta: {
            layout: MainLayout,
            auth: true,
            role: 'report'
        },
    },
    {
        path: '/leave-report',
        name: 'leave-report',
        component: () => import('../views/report/LeaveReport.vue'),
        meta: {
            layout: MainLayout,
            auth: true,
            role: 'report'
        },
    },
    {
        path: '/user-permission',
        name: 'user-permission',
        component: () => import('../views/UserPermissionView.vue'),
        meta: {
            layout: MainLayout,
            auth: true,
            role: 'user'
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFound
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkExactActiveClass: "active",
    routes,
})
router.beforeEach((to, from, next) => {
    const pageStore = usePageStore()
    const sessionStore = useSessionStore()
    pageStore.isLIFF = get(to.meta, 'liff', false) as boolean
    if (to.meta?.auth) {
        if(sessionStore.isLogin) {
            if(sessionStore.checkMenuPermission(to.meta?.role as string)) {
                next()
            }
            next('not-found')
        } else {
            next('login')
        }
    } else if(to.meta?.login && to.name === 'login') {
        if(sessionStore.isLogin){
            next('/')
        }
        next()
    }
    next()
})

export default router
