<script setup lang="ts">
import { ref, watch } from 'vue'
import { usePageStore } from '@/store/page'

const openToast = ref(false)
const timeout = ref()
const pageStore = usePageStore()
watch(() => openToast.value, (value) => {
  if(value) {
    timeout.value = setTimeout(() => {
      openToast.value = false
      pageStore.showToast = false
    }, 3000)
  } else {
    clearTimeout(timeout.value)
  }
})

watch(() => pageStore.showToast, (value) => {
  if(value) {
    openToast.value = true
  }
})
</script>

<template>
  <BToast v-model="openToast" autoHide noHoverPause variant="light"
          toastClass="position-absolute top-0 end-0 toast-index"
          isStatus noCloseButton
          :bodyClass="pageStore.toastInfo.bgClass"
          :title="$t('common.status')"
          :body="pageStore.toastInfo.body"  >
  </BToast>
</template>

<style scoped>
.toast-index {
  z-index: 20000;
}
</style>
