import { defineStore } from 'pinia'
import { ref } from 'vue'
import { ILoginSchema } from '@/adapter/auth-api'
import { isNil } from 'lodash'
import { decodeJwt } from 'jose'
import dayjs from 'dayjs'
import { IJWTPayload } from '@/interface/schema.interface'

export const useSessionStore = defineStore('sessions', () => {
    const isLogin = ref(false)
    const accessToken = ref('')
    const refreshToken = ref('')
    const profile = ref<IJWTPayload>({
        firstname: '',
        lastname: '',
        userId: 0,
        userRoles: ''
    })

    const saveToken = (token: ILoginSchema) => {
        accessToken.value = token.accessToken
        refreshToken.value = token.refreshToken
        localStorage.setItem('tokenJWT', token.accessToken)
        jwtProfile(token.accessToken)
    }

    const jwtProfile = (jwtToken: string) => {
        profile.value = decodeJwt(jwtToken) as IJWTPayload
    }

    const loadSession = () => {
        const oldJwt = localStorage.getItem('tokenJWT')
        if (!isNil(oldJwt)) {
            const expJWT = decodeJwt(oldJwt).exp as number * 1000
            if (dayjs(expJWT).diff(dayjs(), 'minute') < 5) {
                doLogout()
            } else {
                accessToken.value = oldJwt
                isLogin.value = true
                jwtProfile(oldJwt)
            }

        }
    }

    const doLogout = () => {
        isLogin.value = false
        accessToken.value = ''
        refreshToken.value = ''
        localStorage.removeItem('tokenJWT')
        profile.value = {
            firstname: '',
            lastname: '',
            userId: 0,
            userRoles: ''
        }
    }

    const checkMenuPermission = (menu: string) => {
        if(profile.value.userRoles === 'administrator') {
            return true
        } else if(menu === 'request' && profile.value.userRoles === 'hr') {
            return false
        } else if(menu === 'user' || menu === 'employee') {
            return false
        }
        return true
    }

    return {
        isLogin,
        saveToken,
        accessToken,
        refreshToken,
        loadSession,
        doLogout,
        profile,
        checkMenuPermission
    }
})
