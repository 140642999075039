<template>
  <header
      class="dark"
      :class="[
      'header-area bg-white text-center text-md-start pt-15 pb-15 ps-15 pe-15 ps-md-20 pe-md-20 pe-lg-30 transition mb-25 position-fixed',
      { sticky: isSticky },
    ]"
      id="header"
  >
    <div class="row align-items-center">
      <div class="col-xl-11 col-lg-10 col-md-8">
        <div
            class="header-left-side justify-content-center justify-content-md-start d-flex align-items-center"
        >
          <button
              class="header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0"
              id="header-burger-menu"
              @click="handleOpenBurgerMenu"
          >
            <i class="flaticon-menu-3"></i>
          </button>

        </div>
      </div>
      <div class="col-xl-1 col-lg-2 col-md-4">
        <div class="dropdown profile-dropdown justify-content-end">
          <button
              class="dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
          >
            <!--              <img-->
            <!--                  src="../../assets/images/admin.jpg"-->
            <!--                  class="rounded"-->
            <!--                  width="44"-->
            <!--                  height="44"-->
            <!--                  alt="admin"-->
            <!--              />-->
            <span class="title d-none d-lg-block ms-10 ms-lg-15">
                <span class="d-block fw-bold mb-5 mb-md-8">{{ profile.firstname }} {{ profile.lastname }}</span>
                <span class="text-body-emphasis fw-semibold fs-13">Admin</span>
              </span>
          </button>
          <div
              class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0"
          >
            <ul class="ps-0 mb-0 list-unstyled dropdown-body">
              <li
                  class="text-body-secondary fw-semibold transition position-relative"
              >
                <i class="flaticon-logout"></i>
                Logout
                <router-link
                    to="#"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                    @click="handleLogOut()"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { usePageStore } from '@/store/page'
import { useSessionStore } from '@/store/sessions'
import { storeToRefs } from 'pinia'
import router from '@/router'

const pageStore = usePageStore()
const sessions = useSessionStore()
const { profile } = storeToRefs(sessions)

const isSticky = ref(false)

onMounted(() => {
  window.addEventListener('scroll', () => {
    let scrollPos = window.scrollY
    isSticky.value = scrollPos >= 100
  })
  // console.log(sessionsProfile.profile)
})
const handleOpenBurgerMenu = () => {
  pageStore.toggleOpenMenu()
}

const handleLogOut = () => {
  pageStore.setPageLoading(true)
  sessions.doLogout()
  setTimeout(() => {
    pageStore.setPageLoading(false)
    router.push({
      name: 'login'
    })
  }, 1500)

}
</script>

<style scoped>

</style>
