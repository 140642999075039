import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import { createBootstrap } from 'bootstrap-vue-next'
import 'swiper/css'
import 'swiper/css/bundle'

import thLang from './lang/th.json'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
// import 'bootstrap/js/dist/collapse'
// import 'bootstrap/js/dist/dropdown'
// import 'bootstrap'

import './assets/custom.scss'

const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: 'th', // set locale
    fallbackLocale: 'th', // set fallback locale
    messages: {
        th: thLang,
    }, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})

createApp(App)
    .use(store)
    .use(router)
    .use(createPinia())
    .use(createBootstrap({components: true, directives: true}))
    .use(i18n)
    .mount('#app')
