<template>
  <main>
    <LoadingPage v-if="pageStore.pageLoading" />
    <ToastAlert />
    <component :is="$route.meta.layout || 'div'" v-if="$route.meta.layout"/>
    <router-view v-else/>
  </main>
</template>

<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { usePageStore } from '@/store/page'
import { useSessionStore } from '@/store/sessions'
import LoadingPage from '@/components/LoadingPage.vue'
import ToastAlert from '@/components/ToastAlert.vue'

const pageStore = usePageStore()
const sessionStore = useSessionStore()

watch(() => pageStore.openMenu, (value) => {
  if (value) {
    document.body.classList.remove('sidebar-show')
    document.body.classList.add('sidebar-hide')
  } else {
    document.body.classList.add('sidebar-show')
    document.body.classList.remove('sidebar-hide')
  }
})

onMounted(() => {
  sessionStore.loadSession()
})
</script>
<style>

</style>
