<template>
  <div
      :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: pageStore.openMenu },
    ]"
      id="sidebar-area"
  >
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
          to="/"
          class="d-flex align-items-center text-white text-decoration-none"
      >
<!--        <img src="../../assets/images/favicon.png" alt="logo-icon"/>-->
        <span class="fw-bold ms-10">FELIX TIME MANAGEMENT</span>
      </router-link>
      <div class="border-bottom"></div>
      <button
          class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
          @click="handleOpenBurgerMenu"
      >
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul
          class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion"
          id="sidebarNavAccordion"
      >
        <li class="sub-title sidebar-nav-item">
          <span class="d-block text-uppercase fw-medium">Menus</span>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0">
<!--          <router-link to="/" class="sidebar-nav-link d-block">-->
<!--            <i class="flaticon-date"></i>-->
<!--            <span class="title">Attendance</span>-->
<!--          </router-link>-->
          <a
              href="#"
              class="accordion-button rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseOne"
              aria-expanded="true"
              aria-controls="sidebarCollapseOne"
          >
            <i class="flaticon-date"></i>
            <span class="title">Attendance</span>
          </a>
          <div
              id="sidebarCollapseOne"
              class="accordion-collapse collapse show"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item" v-if="sessionStore.checkMenuPermission('today')">
                  <router-link to="/" class="sidebar-sub-menu-link">
                    Today
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-if="sessionStore.checkMenuPermission('request')">
                  <router-link
                      to="/request-attendance"
                      class="sidebar-sub-menu-link"
                  >
                    Request
                  </router-link>
                  <router-link
                      to="/manual-attendance"
                      class="sidebar-sub-menu-link"
                  >
                    Manual
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item" v-if="sessionStore.checkMenuPermission('employee')">
          <router-link to="/employee" class="sidebar-nav-link d-block">
            <i class="flaticon-setting"></i>
            <span class="title">Employee</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item" v-if="sessionStore.checkMenuPermission('holiday')">
          <router-link to="/holiday" class="sidebar-nav-link d-block">
            <i class="flaticon-edit"></i>
            <span class="title">Holiday</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0" v-if="sessionStore.checkMenuPermission('report')">
          <!--          <router-link to="/" class="sidebar-nav-link d-block">-->
          <!--            <i class="flaticon-date"></i>-->
          <!--            <span class="title">Attendance</span>-->
          <!--          </router-link>-->
          <a
              href="#"
              class="accordion-button rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseTwo"
              aria-expanded="true"
              aria-controls="sidebarCollapseTwo"
          >
            <i class="flaticon-date"></i>
            <span class="title">Report</span>
          </a>
          <div
              id="sidebarCollapseTwo"
              class="accordion-collapse collapse show"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/attendance-report"
                      class="sidebar-sub-menu-link"
                  >
                    Attendance
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/leave-report" class="sidebar-sub-menu-link">
                    Leave
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item" v-if="sessionStore.checkMenuPermission('user')">
          <router-link to="/user-permission" class="sidebar-nav-link d-block">
            <i class="flaticon-user-3"></i>
            <span class="title">User & Permission</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePageStore } from '@/store/page'
import { onMounted, watch } from 'vue'
import { useSessionStore } from '@/store/sessions'

const pageStore = usePageStore()
const sessionStore = useSessionStore()

watch(() => pageStore.openMenu, (value) => {

})



const handleOpenBurgerMenu = () => {
  pageStore.toggleOpenMenu()
}

const checkPermission = (menu: string) => {
  const userRole = sessionStore.getUserRole()
}

</script>

<style scoped>

</style>
