<template>
  <div class="main-content d-flex flex-column transition overflow-hidden custom-liff-div">
    <router-view/>
  </div>
</template>

<script setup lang="ts">
import {onBeforeMount} from "vue";
import VConsole from "vconsole";
import {onMounted} from "vue";

onBeforeMount(() => {
  if(process.env.NODE_ENV !== 'production') {
    const vConsole = new VConsole()
  }
})

onMounted(() => {
  document.body.classList.toggle("dark", false)
  document.body.classList.remove("bg-body-secondary");
})
</script>

<style scoped>
.custom-liff-div {
  margin-top: calc(10% - 170px);
}
</style>
