<template>
  <router-view/>
</template>

<script setup>
import {onMounted} from "vue";

onMounted(() => {
  document.body.classList.toggle("dark", true)
  document.body.classList.add("bg-body-secondary");
})
</script>

<style scoped>

</style>
