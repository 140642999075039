<template>
  <MainHeader/>
  <MainSidebar/>
  <div class="dark main-content  d-flex flex-column transition overflow-hidden">
    <router-view/>
    <!--    <MainFooter />-->
  </div>
</template>

<script setup lang="ts">
import MainHeader from '../components/MainHeader'
import MainSidebar from '../components/MainSidebar'
import { onMounted } from 'vue'

onMounted(() => {
  document.body.classList.toggle('dark', true)
  document.body.classList.add('bg-body-secondary')
})
</script>

<style scoped>

</style>
